import React from 'react'
import {graphql} from 'gatsby'
import Header from '../layouts/header'
import Footer from '../layouts/footer'
import Canvas from '../layouts/canvas'
import Tabs from '../layouts/tabs'
import Breadcrumb from '../layouts/breadcrumb'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser';
import Inparser from '../functions/inparser'
import {Helmet} from 'react-helmet'

class SimplePage extends React.Component {

  componentDidMount() {
    var $ = window.$;

    $('.to_animate').addClass('animated');

    $("[data-animation=fadeInRight]").addClass('fadeInRight')
    $("[data-animation=fadeInLeft]").addClass('fadeInLeft')
    $("[data-animation=fadeInUp]").addClass('fadeInUp')




    $('.owl-carousel').each(function() {
      var $carousel = $(this);
      $carousel.find('> *').each(function (i) {
        $(this).attr('data-index', i);
      });
      var data = $carousel.data();

      var loop =  false,
          margin = (data.margin || data.margin === 0) ? data.margin : 30,
          nav = data.nav ? data.nav : false,
          navPrev = data.navPrev ? data.navPrev : '<i class="fa fa-angle-left">',
          navNext = data.navNext ? data.navNext : '<i class="fa fa-angle-right">',
          dots = data.dots ? data.dots : false,
          themeClass = data.themeclass ? data.themeclass : 'owl-theme',
          center = data.center ? data.center : false,
          items = data.items ? data.items : 4,
          autoplay = data.autoplay ? data.autoplay : false,
          responsiveXs = data.responsiveXs ? data.responsiveXs : 1,
          responsiveSm = data.responsiveSm ? data.responsiveSm : 2,
          responsiveMd = data.responsiveMd ? data.responsiveMd : 3,
          responsiveLg = data.responsiveLg ? data.responsiveLg : 4,
          draggable = (data.draggable === false) ? data.draggable : true,
          syncedClass = (data.syncedClass) ? data.syncedClass : false,
          filters = data.filters ? data.filters : false;

      if (filters) {
        $carousel.after($carousel.clone().addClass('owl-carousel-filter-cloned'));
        $(filters).on('click', 'a', function( e ) {
          //processing filter link
          e.preventDefault();
          if ($(this).hasClass('selected')) {
            return;
          }
          var filterValue = $( this ).attr('data-filter');
          $(this).siblings().removeClass('selected active');
          $(this).addClass('selected active');

          //removing old items
          for (var i = $carousel.find('.owl-item').length - 1; i >= 0; i--) {
            $carousel.trigger('remove.owl.carousel', [1]);
          };

          //adding new items
          var $filteredItems = $($carousel.next().find(' > ' +filterValue).clone());
          $filteredItems.each(function() {
            $carousel.trigger('add.owl.carousel', $(this));
            $(this).addClass('scaleAppear');
          });

          $carousel.trigger('refresh.owl.carousel');

          //reinit prettyPhoto in filtered OWL carousel
          if ($().prettyPhoto) {
            $carousel.find("a[data-gal^='prettyPhoto']").prettyPhoto({
              hook: 'data-gal',
              theme: 'facebook' /* light_rounded / dark_rounded / light_square / dark_square / facebook / pp_default*/
            });
          }
        });

      } //filters

      $carousel.owlCarousel({
        loop: loop,
        margin: margin,
        nav: nav,
        autoplay: autoplay,
        dots: dots,
        themeClass: themeClass,
        center: center,
        navText: [navPrev,navNext],
        mouseDrag: draggable,
        touchDrag: draggable,
        items: items,
        responsive: {
          0:{
            items: responsiveXs
          },
          767:{
            items: responsiveSm
          },
          992:{
            items: responsiveMd
          },
          1200:{
            items: responsiveLg
          }
        },
      })
          .addClass(themeClass);
      if(center) {
        $carousel.addClass('owl-center');
      }



      //topline two synced carousels
      if($carousel.hasClass('owl-news-slider-items') && syncedClass) {
        $carousel.on('changed.owl.carousel', function(e) {
          var indexTo = loop ? e.item.index+1 : e.item.index;
          $(syncedClass).trigger('to.owl.carousel', [indexTo]);
        })
      }


    });




  }
  render() {

    const nodeId = this.props.pageContext.slug;
    const baseUrl = this.props.pageContext.baseurl;
    const paypalClientId =  process.env.PAYPAL_CLIENT_ID;

    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
      menu = this.props.pageContext.menuen
    }
    else {
      menu = this.props.pageContext.menuel
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({url:item.entityUrl.path, id:item.entityLanguage.id})
    })
    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }


      console.log(this.props.data.drupal.nodeById);
      console.log(this.props.data.drupal.nodeById.body);



    })
    return (
        <div>
          <Helmet>
            {this.props.pageContext.metainfo.map((item,i) => {
              if(item.key === 'title') {
                return (
                  [<title key={i}>{item.value}</title>,<meta property="og:title" content={item.value} />]
                )
              }
              else if(item.key === 'description') {
                return (
                  [ <meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]
                )
              }

              else {
                return null;
              }
            })}
            <link  rel="canonical" href={process.env.FRONT_URL+this.props.path+'/'} />
            <meta property="og:type" content="Website" />
            <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
            <meta property="og:image" content={`${process.env.FRONT_URL}${this.props.data.drupal.nodeById.fieldImage.imageFile.childImageSharp.fluid.src}`} />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
          </Helmet>
          <Canvas>
            <Header language={this.props.pageContext.langcode} menu={menu} langpath={langpath}/>
            <Breadcrumb title={this.props.data.drupal.nodeById.title} path={loc}/>

            <section className="ls s-pt-30 s-pt-md-50 s-pb-md-10  c-gutter-60 c-mb-30 c-mb-md-50 overflow-visible margin100 padding0">
              <div className="divider-65 d-none d-lg-block"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 service-single to_animate animate" data-animation="fadeInRight">

                    {this.props.data.drupal.nodeById.body?
                        <Inparser
                            body={this.props.data.drupal.nodeById.body}
                            baseurl={this.props.pageContext.baseurl}
                            fileQuery={this.props.data.drupal.fileQuery.entities}

                        />

                        :null}
                  </div>
                  <div className="col-lg-6 to_animate fw-column animate" data-animation="fadeInLeft">
                    {this.props.data.drupal.nodeById.fieldImage? <Img fluid={this.props.data.drupal.nodeById.fieldImage.imageFile.childImageSharp.fluid} alt={this.props.data.drupal.nodeById.fieldImage.alt}/> : <img src={"https://loremflickr.com/320/340"} alt=""/> }

                  </div>

                  <div className="col-lg-12 to_animate fw-column animate" data-animation="fadeInUp">
                    {this.props.data.drupal.nodeById.fieldBody2?
                        <Inparser
                            body={this.props.data.drupal.nodeById.fieldBody2}
                            baseurl={this.props.pageContext.baseurl}
                            fileQuery={this.props.data.drupal.fileQuery.entities}

                        />

                        : null}
                  </div>
                  {/*<Tabs baseurl={this.props.pageContext.baseurl} langcode={this.props.pageContext.langcode} tabtitle={this.props.data.drupal.nodeById.fieldTabsTitle} formtitle={this.props.data.drupal.nodeById.fieldTitleForWebform} tabs={this.props.data.drupal.tabs.entities}/>*/}
                  <div className="col-lg-12 to_animate fw-column animate" data-animation="fadeInUp">


                    {this.props.data.drupal.nodeById.fieldBody3?
                        <Inparser
                            body={this.props.data.drupal.nodeById.fieldBody3}
                            baseurl={this.props.pageContext.baseurl}
                            fileQuery={this.props.data.drupal.fileQuery.entities}

                        />

                        : null}
                  </div>

                </div>
                <div className="divider-10 d-none d-lg-block"></div>
              </div>

            </section>

            <Footer/>

          </Canvas>
        </div>
    )
  }


}
export default SimplePage;

export const query = graphql`
query($slug: String!, $langcode: DRUPAL_LanguageId!, $parsed:[DRUPAL_EntityQueryFilterConditionInput])  {
  drupal {
    nodeById(id: $slug,  language: $langcode) {
      title
      entityLabel
      ... on DRUPAL_NodeSimplepage {

        fieldTitleForWebform
        body {
          processed
        }
        fieldImage {
          url
          alt
          imageFile {
             childImageSharp {
               fluid(maxWidth: 800, quality: 90, fit: COVER) {

                 ...GatsbyImageSharpFluid
               }
             }
           }
        }
        fieldBody2 {
          processed
        }
        fieldBody3 {
          processed
        }

      }



    }



    fileQuery(limit: 300, filter: {conditions: $parsed, conjunction: OR}) {
      count
      entities {
        ... on DRUPAL_File {
          url
          imageFile {
                  id
                 publicURL
                 }
        }
      }
    }


  }
}
`;
